<template>
  <div />
</template>
<script>
import { RESEND_CONFIRMATION } from '@/constants'

export default {
  name: RESEND_CONFIRMATION,
}
</script>
